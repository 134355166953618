exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dank-je-tsx": () => import("./../../../src/pages/dank-je.tsx" /* webpackChunkName: "component---src-pages-dank-je-tsx" */),
  "component---src-pages-groepslessen-tsx": () => import("./../../../src/pages/groepslessen.tsx" /* webpackChunkName: "component---src-pages-groepslessen-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-individuele-lessen-tsx": () => import("./../../../src/pages/individuele-lessen.tsx" /* webpackChunkName: "component---src-pages-individuele-lessen-tsx" */),
  "component---src-pages-over-mij-tsx": () => import("./../../../src/pages/over-mij.tsx" /* webpackChunkName: "component---src-pages-over-mij-tsx" */),
  "component---src-pages-voor-bedrijven-tsx": () => import("./../../../src/pages/voor-bedrijven.tsx" /* webpackChunkName: "component---src-pages-voor-bedrijven-tsx" */),
  "component---src-pages-voor-kinderen-tsx": () => import("./../../../src/pages/voor-kinderen.tsx" /* webpackChunkName: "component---src-pages-voor-kinderen-tsx" */),
  "component---src-pages-voor-senioren-tsx": () => import("./../../../src/pages/voor-senioren.tsx" /* webpackChunkName: "component---src-pages-voor-senioren-tsx" */)
}

